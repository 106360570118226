.tabs {
  max-width: 100%;
  // min-height: 300px;
  overflow-x: hidden;

  .tab__nav {
    flex-wrap: wrap;
  }

  ul {
    list-style: none;

    li {
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: #111, $alpha: 0.5);
    }

    .active__tab {
      border-bottom: 2px solid #2b2c54;
      background: #2b2c54;
      color: #fff;
      font-weight: 600;
    }
  }

  .tab__outlet {
    padding: 2rem 1rem;
  }
}

.tabs > ul {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 24px;
  overflow-x: auto !important;
  // border-bottom: 1px solid rgba($color: #eee, $alpha: 0.9);
  overflow-x: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabs > ul li {
  background: #fff;
  border: 2px solid transparent;
  margin: 16px 0;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 980px) {
  .tabs {
    .tab__nav {
      justify-content: space-between;
    }
  }
}

@media (max-width: 620px) {
  .tabs {
    ul {
      width: 100% !important;

      li {
        font-size: 14px;
        height: 30px;
        padding: 0.3rem 0.5rem;
        width: max-content !important;
      }
    }
  }

  .tab__nav > li {
    font-size: 12px !important;
  }

  .tab-outlet {
    padding: 2rem 1rem !important;
  }
}

@media (max-width: 395px) {
  .tabs {
    ul {
      li {
        font-size: 12px;
      }
    }
  }

  .tabs > ul {
    align-items: baseline !important;
    gap: 16px !important;
  }

  .tab__nav li {
    font-size: 14px;
    border-radius: 20px;
    width: fit-content !important;
  }
}
