@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/ProductSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.brand-wheel {
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100px;

  --animationspeed: 45s;
  --animationdelay: calc(var(--animationspeed) / 2);
}

.brand-slide {
  flex-shrink: 0;
  position: absolute;
  animation-name: slidelogo;
  animation-duration: var(--animationspeed);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  min-width: 100%;
  display: flex;
  justify-content: space-around;
}

.brand-slide.delay {
  transform: translateX(100%);
  animation-name: slidelogo2;
}

.brand-wheel:hover .brand-slide {
  animation-play-state: paused;
}

.logo-div {
  display: inline-flex;
  align-self: center;
  animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    forwards;
  padding: 0 20px 0 20px;
}

.test {
  width: 100px;
  height: 300px;
  background: green;
  position: relative;
  display: inline-block;
}

.test:before {
  background: white;
  height: 300px;
  width: 30px;
  border-radius: 0 60px 60px 0 / 0 300px 300px 0;
  display: inline-block;
  content: "";
}

.test:after {
  background: white;
  height: 300px;
  width: 30px;
  border-radius: 60px 0 0 60px / 300px 0 0 300px;
  display: inline-block;
  content: "";
  position: relative;
  left: 40px;
}

@keyframes slidelogo {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slidelogo2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
