.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000000;

  @keyframes spin {
    to {
      transform: rotateZ(360deg);
    }
  }

  span {
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    border: 2px solid transparent;
    border-top-color: #2b2c54;
    border-radius: 50%;
    animation: spin ease 1000ms infinite;
  }
}
