.course {
    html,
    body {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
    }
  
    .course__container {
      .title__container {
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        gap: 48px;
        padding: 24px 0;
        height: fit-content;
  
        .title__description {
          width: 70%;
  
          .title__description__heading {
            display: flex;
            align-items: center;
  
            .title__logo {
              width: 78px;
              height: 60px;
              margin-bottom: 1rem;
  
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
  
            h3 {
              color: #111;
              font-size: 30px;
            }
          }
  
          .title__description__paragraph {
            width: 95%;
  
            p {
              font-size: 15px;
              line-height: 1.8;
            }
          }
        }
  
        .title__tags {
          padding: 16px 0;
          display: flex;
          gap: 8px;
          width: fit-content;
  
          .tag__courses,
          .tag__hours,
          .tag__projects,
          .tag__resources {
            padding: 4px 12px;
            border-radius: 25px;
            border: 2px solid #2b2c54;
          }
  
          p {
            font-size: 12px;
            font-weight: 500;
            color: #2b2c54;
          }
        }
  
        .title__media {
          width: 30%;
  
          .title__media__container {
            width: 100% !important;
            height: 100%;
            padding-bottom: 16px;
  
            .media__container {
              width: 100% !important;
              display: flex;
              flex-direction: column;
              height: inherit;
              gap: 16px;
  
              video {
                width: 100%;
                height: 250px;
              }
  
              .media__getstarted__button {
                width: 100% !important;
  
                button {
                  outline: none;
                  border: 1px solid #2b2c54;
                  background: transparent;
                  border-radius: 4px;
                  width: 100% !important;
                  padding: 12px 24px;
                  font-size: 14px;
                  font-weight: 500;
                  cursor: pointer;
                  color: #2b2c54;
  
                  &:hover {
                    background: rgb(245, 243, 243);
                  }
                }
              }
            }
          }
        }
      }
  
      // content & tutors
      .content__and__tutors {
        height: fit-content;
        padding: 16px 0;
        margin: 2rem 0;
  
        .content__and__tutors__container {
          max-width: 1100px;
          margin: 0 auto;
  
          .content__heading {
            padding-bottom: 8px;
  
            h3 {
              text-transform: uppercase;
              font-size: 14px;
              color: #2b2c54;
            }
          }
  
          .content__and__tutors__items {
            display: flex;
            gap: 48px;
          }
  
          .content__items__container {
            width: 70%;
          }
  
          .content__items {
            height: fit-content;
            width: 100%;
            padding: 32px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
              rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  
            .circle__border__container {
              display: flex;
              flex-direction: column;
              gap: 6px;
  
              .circle__and__description,
              .border__and__paragraph {
                display: flex;
                width: fit-content;
                align-items: center;
                gap: 16px;
  
                .circle {
                  width: 14px;
                  height: 14px;
                  border-radius: 50%;
                  border: 1px solid #2b2c54;
                }
  
                .border {
                  border-left: 1px solid #2b2c54;
                  border-left-width: thin;
                  height: 100px;
                  margin-left: 1%;
                }
  
                .content__right__heading {
                  h3 {
                    font-size: 18px;
                    color: #2b2c54;
                  }
                }
  
                .content__right__paragraph {
                  p {
                    font-size: 13px;
                    line-height: 1.8;
                    margin-left: 1%;
                  }
                }
              }
  
              .content__right__tags {
                display: flex;
                margin: 1% 0 1% 0;
                gap: 8px;
                width: fit-content;
  
                p {
                  font-size: 12px;
                  padding: 4px 12px;
                  border-radius: 25px;
                  border: 2px solid #2b2c54;
                  color: #2b2c54;
                  font-weight: 400;
                }
              }
            }
          }
  
          .tutors {
            width: 30%;
            height: fit-content;
  
            .tutors__container {
              .tutors__heading {
                padding-bottom: 8px;
  
                h3 {
                  text-transform: uppercase;
                  font-size: 14px;
                  color: #2b2c54;
                }
              }
  
              .tutors__description__container {
                height: fit-content;
                padding: 32px;
                display: flex;
                flex-direction: column;
                gap: 32px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  
                .tutor__description {
                  width: 100%;
                  display: flex;
                  gap: 8px;
  
                  .tutor__img {
                    width: 40px;
                    height: 40px;
  
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      // object-fit: cover;
                      border: 1px solid #2b2c54;
                    }
                  }
  
                  .tutor__info {
                    .tutor__name {
                      h3 {
                        font-size: 15px;
                        color: #111;
                      }
                    }
  
                    .tutor__job__title {
                      display: flex;
                      flex-wrap: wrap;
  
                      p {
                        font-size: 14px;
                        white-space: break-spaces;
                        color: #808080;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  
      // additional courses
      .additional__courses {
        height: fit-content !important;
        background: #000;
        padding: 32px 0;
  
        .additional__courses__container {
          // height: 380px;
          max-width: 1100px;
          margin: 0 auto;
  
          .additional__courses__heading {
            padding: 16px 0;
  
            h3 {
              font-size: 14px;
              text-transform: uppercase;
              color: #ff8c00;
            }
          }
  
          .additional__courses__content {
            padding: 16px 32px;
            border: 1px solid rgba($color: #fff, $alpha: 0.3);
            border-radius: 4px;
            cursor: pointer;
  
            .additional__courses__item {
              padding: 8px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
  
              h3 {
                font-size: 15px;
                color: #fff;
              }
            }
          }
  
          .additional__courses__details {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 16px;
            flex-wrap: wrap;
            border-top: 1px solid rgba($color: #111, $alpha: 0.08);
            padding: 16px 0;
  
            p,
            h3 {
              color: #fff;
            }
          }
        }
  
        .keyboard__down__icon,
        .keyboard__right__icon {
          font-size: 20px;
          color: #ff8c00;
        }
      }
  
      // organizations
      .organizations {
        padding: 16px 0;
        height: 200px;
  
        .organizations__container {
          max-width: 1100px;
          margin: 0 auto;
          height: 480px;
  
          .organizations__heading {
            text-transform: uppercase;
            font-size: 14px;
            text-align: center;
            padding-top: 16px;
          }
  
          .organizations__logos__container {
            width: 100%;
            padding: 16px 0;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
  
            .organizations__logos {
              width: 70px;
              padding: 16px 0;
  
              img {
                max-width: 100%;
              }
            }
          }
        }
      }
  
      // join course
      .join__course {
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        .join__course__container {
          max-width: 1100px;
          margin: 24px auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;
  
          .join__course__heading__container {
            text-align: center;
  
            h3 {
              font-size: 32px;
              color: #2b2c54;
              width: 700px;
            }
  
            p {
              font-size: 18px;
              padding: 16px 0;
              margin: 0 auto;
              color: #2b2c54;
            }
          }
  
          .join__course__button {
            button {
              outline: none !important;
              border: none !important;
              background: #2b2c54;
              color: #fff;
              font-weight: 700;
              font-size: 14px;
              border-radius: 4px;
              padding: 18px 100px;
              cursor: pointer;
              font-size: 16px;
  
              &:hover {
                border: #2a2da3;
                background: #15175c;
              }
            }
          }
        }
      }
  
      // carousel
      .course__carousel {
        background: #000;
        height: fit-content;
        padding: 32px 0;
  
        .course__carousel__container {
          max-width: 1100px;
          margin: 24px auto;
  
          .course__carousel__heading {
            font-size: 14px;
            text-transform: uppercase;
            color: #ff8c00;
          }
  
          .course__carousel__subheading {
            font-size: 32px;
            width: 350px;
            padding-top: 16px;
            color: #fff;
          }
        }
      }
  
      // faqs
      .faq {
        background: #000;
        padding: 32px 0;
  
        .faq__container {
          max-width: 1100px;
          margin: 0 auto;
          padding: 32px;
  
          .faq__heading {
            font-size: 14px;
            color: #ff8c00;
          }
  
          .faq__subheading {
            font-size: 32px;
            padding: 16px 0;
            width: 350px;
          }
        }
  
        h3,
        p {
          color: #fff;
        }
      }
    }
  }
  
  // media queries
  @media (max-width: 620px) {
    html,
    body {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
    }
  
    .course {
      .course__container {
        width: 100%;
  
        .title__container {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 32px;
          height: fit-content;
  
          // title
          .title__description {
            width: 100%;
  
            .title__description__heading {
              h3 {
                font-size: 26px !important;
              }
            }
  
            .title__description__paragraph {
              width: 100%;
              padding-top: 8px;
  
              p {
                font-size: 15px !important;
              }
            }
          }
  
          .title__tags {
            .tag__courses,
            .tag__hours,
            .tag__projects,
            .tag__resources {
              padding: 2px 8px !important;
            }
  
            p {
              font-size: 14px !important;
            }
          }
  
          .title__media {
            width: 100%;
  
            .title__media__container {
              .media__container {
                .media__getstarted__button {
                  text-align: center !important;
                  width: 100% !important;
  
                  button {
                    width: 100% !important;
                    outline: none !important;
                    font-size: 16px !important;
                    color: #2b2c54;
                  }
                }
              }
            }
          }
        }
      }
  
      // content and tutors
      .content__and__tutors {
        padding: 16px !important;
        height: fit-content !important;
  
        .content__and__tutors__items {
          display: flex;
          flex-direction: column;
        }
  
        .content__items__container {
          width: 100% !important;
          height: fit-content !important;
  
          .content__heading {
            h3 {
              font-size: 16px !important;
            }
          }
  
          .content__items {
            width: inherit !important;
          }
  
          .content__right__heading {
            h3 {
              font-size: 20px !important;
            }
          }
  
          .border {
            height: 250px !important;
            margin-left: 2% !important;
          }
  
          .content__right__tags {
            margin: 5% 0 !important;
  
            p {
              font-size: 14px !important;
            }
          }
        }
  
        .content__heading {
          padding-bottom: 8px !important;
        }
  
        .tutors {
          width: 100% !important;
  
          .tutors__container {
            .tutors__heading {
              padding-bottom: 8px !important;
  
              h3 {
                font-size: 16px !important;
              }
            }
  
            .tutors__description__container {
              .tutor__img {
                width: 50px !important;
                height: 50px !important;
              }
            }
  
            .tutor__info {
              .tutor__name {
                h3 {
                  font-size: 16px !important;
                }
              }
  
              .tutor__job__title {
                p {
                  font-size: 15px !important;
                }
              }
            }
          }
        }
      }
  
      // additional courses
      .additional__courses {
        height: fit-content !important;
        padding: 32px 16px !important;
  
        .additional__courses__heading {
          h3 {
            font-size: 14px !important;
          }
        }
  
        .additional__courses__item {
          h3 {
            font-weight: 300 !important;
            font-size: 15px !important;
          }
        }
      }
  
      // organizations
      .organizations {
        padding: 16px !important;
        height: fit-content !important;
        width: 100% !important;
  
        .organizations__container {
          height: fit-content !important;
  
          .organizations__heading {
            font-size: 14px !important;
          }
  
          .organizations__logos__container {
            display: flex;
            width: 100% !important;
            flex-wrap: wrap;
            justify-content: center !important;
          }
        }
      }
  
      //carousel
      .course__carousel {
        padding: 32px 16px !important;
  
        .course__carousel__container {
          padding: 0 8px !important;
        }
  
        .course__carousel__heading {
          font-size: 14px !important;
        }
  
        .course__carousel__subheading {
          font-size: 32px !important;
        }
      }
  
      // join course
      .join__course {
        width: 100% !important;
        padding: 16px !important;
        overflow-x: hidden !important;
  
        .join__course__container {
          width: 100% !important;
  
          h3 {
            font-size: 32px !important;
          }
  
          p {
            font-size: 18px !important;
          }
  
          .join__course__button {
            width: 100% !important;
  
            button {
              width: 100% !important;
              text-align: center;
              font-size: 15px !important;
            }
          }
        }
      }
  
      .faq__heading {
        font-size: 14px !important;
      }
  
      .faq__subheading {
        font-size: 32px !important;
      }
  
      h3 {
        font-size: 18px !important;
      }
  
      p {
        font-size: 15px !important;
      }
    }
  }
  
  @media (min-width: 625px) and (max-width: 980px) {
    .course {
      html,
      body {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
      }
  
      .course__container {
        // title
        .title__container {
          padding: 16px !important;
          height: fit-content !important;
  
          .title__description {
            width: 60% !important;
  
            .title__description__paragraph {
              p {
                font-size: 18px !important;
              }
            }
          }
  
          .title__tags {
            p {
              font-size: 15px !important;
            }
          }
        }
  
        .title__media {
          width: 40% !important;
          height: fit-content !important;
  
          .title__media__container {
            .media__container {
              video {
                width: 100% !important;
              }
  
              .media__getstarted__button {
                width: 100% !important;
                text-align: center !important;
              }
            }
          }
        }
  
        // content and tutors
        .content__and__tutors {
          height: fit-content !important;
          padding: 16px !important;
  
          .content__and__tutors__container {
            .content__and__tutors__items {
              display: flex;
              flex-direction: column !important;
  
              .content__items__container {
                width: 100% !important;
  
                .content__items {
                  width: inherit !important;
  
                  .content__right__heading {
                    h3 {
                      font-size: 24px !important;
                    }
                  }
  
                  .content__right__paragraph {
                    p {
                      font-size: 18px !important;
                    }
                  }
  
                  .content__right__tags {
                    p {
                      font-size: 15px !important;
                    }
                  }
                }
  
                .border {
                  height: 140px !important;
                }
              }
  
              .tutors {
                width: 100% !important;
  
                .tutors__container {
                  .tutors__description__container {
                    display: flex;
                    flex-direction: row !important;
                    justify-content: space-between;
                    padding: 32px 16px !important;
  
                    .tutor__img {
                      width: 50px !important;
                      height: 50px !important;
                    }
  
                    .tutor__info {
                      .tutor__name {
                        h3 {
                          font-size: 18px !important;
                        }
                      }
  
                      .tutor__job__title {
                        p {
                          font-size: 15px !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
  
        // additional courses
        .additional__courses {
          padding: 32px 16px !important;
  
          .additional__courses__container {
            height: fit-content !important;
  
            .additional__courses__item {
              h3 {
                font-size: 18px !important;
              }
            }
  
            .additional__courses__details {
              p {
                font-size: 16px !important;
              }
            }
  
            .keyboard__down__icon,
            .keyboard__right__icon {
              font-size: 22px !important;
            }
          }
        }
  
        // organizations
        .organizations {
          padding: 32px 16px !important;
          height: fit-content;
  
          .organizations__container {
            height: fit-content;
          }
        }
  
        // carousel
        .course__carousel {
          padding: 32px 16px !important;
  
          .course__carousel__container {
            padding: 0 8px !important;
          }
        }
      }
    }
  }
  
  @media (min-width: 982px) and (max-width: 1100px) {
    .title__container,
    .content__and__tutors,
    .additional__courses {
      padding: 32px 16px !important;
    }
  
    .content__and__tutors {
      height: auto !important;
    }
  
    .organizations {
      padding: 16px !important;
    }
  
    .course__carousel {
      padding: 32px 16px !important;
  
      .course__carousel__container {
        padding: 0 8px !important;
      }
    }
  }
  
  @media (min-width: 280px) and (max-width: 380px) {
    .tag__courses,
    .tag__hours,
    .tag__projects,
    .tag__resources {
      padding: 2px 3px !important;
  
      p {
        font-size: 10px !important;
      }
    }
  
    .organizations__logos__container {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;
    }
  
    .course__carousel {
      height: fit-content !important;
    }
  
    .faq__subheading,
    .course__carousel__subheading {
      width: fit-content !important;
    }
  }
  