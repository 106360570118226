.navbar__header {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: fixed;
  // top: 0;
  width: 100%;
  z-index: 9999;
}

.navbar {
  height: 60px;
  width: 100%;
  background: #fff;
  color: #111;

  .navbar__items {
    max-width: 1100px;
    height: inherit;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar__menu__container {
      // width: fit-content;
      height: inherit;
      display: flex;
      align-items: center;
      gap: 32px;
      position: relative;

      .navbar__logo {
        width: 100px;
        height: 28px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .navbar__actions {
      width: fit-content;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      align-items: center;

      .navbar__auth {
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }
    }
  }
}

// media queries
@media (min-width: 989px) and (max-width: 1200px) {
  .navbar__header {
    .navbar {
      .navbar__items {
        margin: 0 !important;
      }
    }
  }

  .navbar__items {
    min-width: 100% !important;
  }
}
