.not__found {
  display: flex;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  .not__found__container {
    max-width: 1100px;
    margin: 0 auto;

    .error__container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;

      .error__img__container {
        width: 50%;
        height: 480px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .error__text__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        h3 {
          font-size: 32px;
          line-height: 1.7;
          color: #111;
          font-weight: 800;
          text-align: center;
        }

        .error__text__more {
          margin-bottom: 2rem;

          p {
            font-size: 18px;
            line-height: 1.7;
            color: #808080;
            text-align: center;
          }
        }

        button {
          width: fit-content;
          outline: none;
          border: none;
          padding: 10px 32px;
          border-radius: 4px;
          cursor: pointer;
          transition: 0.3s all ease-in;
          background: #2b2c54;
          color: #fff;
          font-size: 14px;

          &:hover {
            background: #15175c;
          }
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .not__found {
    height: fit-content !important;

    .error__container {
      display: flex;
      flex-direction: column-reverse !important;
      height: 100% !important;
      align-items: center !important;
      justify-content: center !important;

      .error__img__container {
        width: 100% !important;
      }
    }

    .error__text__container {
      padding: 16px !important;
      margin-bottom: 2rem !important;

      h3 {
        font-size: 28px;
      }
    }
  }
}

@media (min-width: 625px) and (max-width: 980px) {
  .not__found {
    height: 100vh;
    padding: 16px !important;

    .error__container {
      display: flex;
      justify-content: center !important;
      align-items: center !important;
    }
  }
}
