.mobile__navbar {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #fff;
  color: #111;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  z-index: 9999999;
  position: fixed;
  // top: 0;

  .mobile__navbar__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobile__navbar__logo {
      width: 150px;
      height: 28px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .mobile__navbar__icon {
      display: flex;
      align-items: center;

      .mobile__menu__icon,
      .mobile__search__icon {
        color: #2b2c54;
        font-size: 32px;
      }
    }
  }

  .mobile__navbar__elements {
    top: 0;
  }

  .mobile__navbar__elements__banner {
    top: 50px;
  }

  .mobile__navbar__elements,
  .mobile__navbar__elements__banner {
    width: calc(100vw - 50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    z-index: 9999999;
    border-radius: 4px 0 0 4px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    overflow-y: auto !important;

    .navbar__element__logo__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .navbar__element__logo {
        width: 150px;
        height: auto;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .close__icon {
      color: #2b2c54;
      font-size: 32px;
    }

    .menu__element__container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      margin: 32px 0;
      padding: 16px 0;

      .menu__element__item {
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba($color: #111, $alpha: 0.2);

        ul {
          list-style: none;

          li {
            cursor: pointer;
            color: #808080;
            padding: 8px 0;
            font-size: 13px;
          }
        }

        p {
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          color: #111;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .menu__elements {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;

    .menu__element__container {
      display: flex !important;
      flex-direction: row !important;
      width: inherit !important;
      flex-wrap: wrap !important;
      justify-content: space-between;

      .menu__element__item {
        border-bottom: none !important;
      }
    }
  }
  .mobile__signin__button,
  .mobile__getstarted__button {
    width: 300px !important;
    margin: 0 auto !important;
    padding: 20px 36px !important;
    position: absolute;
  }

  .mobile__signin__button {
    bottom: 15% !important;
    left: 8% !important;
  }

  .mobile__getstarted__button {
    bottom: 15% !important;
    right: 8% !important;
  }
}

@media (min-width: 768px) and (max-height: 480px) {
  .menu__elements {
    height: 55% !important;

    .menu__element__container {
      .menu__element__item {
        p {
          font-size: 18px !important;
        }

        li {
          font-size: 14px !important;
        }
      }
    }
  }
  .mobile__signin__button,
  .mobile__getstarted__button {
    margin: 0 auto !important;
    padding: 12px 48px !important;
    position: absolute;
    width: 250px !important;
  }

  .mobile__signin__button {
    bottom: 4% !important;
    left: 8% !important;
  }

  .mobile__getstarted__button {
    bottom: 4% !important;
    right: 8% !important;
  }
}
