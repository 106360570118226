/* do not remove tailwind base style, overrides default styling, default styling is messed up since tailwind installation */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --scale-factor: 1.25;
}

html,
body {
  font-family: "ProductSans";
  font-weight: 300;
  color: #111;
}

button {
  font-family: "ProductSans";
  font-weight: 300;
}

input,
textarea {
  font-weight: 300 !important;
}

input::placeholder {
  font-weight: 300 !important;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.spinner::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin ease 1000ms infinite;
}

.custom-progress {
  position: relative;
  height: 24px;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 4px;
}
.percent::before {
  content: attr(data-value) "%" " Completed";
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 14px;
  color: white;
  font-weight: 600;
  text-shadow: 0 0 2px #2b2c54;
  z-index: 10;
  top: 1px;
}
.custom-progress::-webkit-progress-bar {
  background-color: #c1c2cd;
}
.custom-progress::-moz-progress-bar {
  background-color: #c1c2cd;
}
.custom-progress::-webkit-progress-value {
  background-color: #2b2c54;
}
.custom-progress::-moz-progress-bar {
  background-color: #2b2c54;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  scrollbar-width: none;
}

.student_comments h4 {
  font-weight: 700;
}

.student_comments ul {
  list-style: disc;
  padding-left: 32px;
}

.student_comments blockquote {
  margin: 0 10px;
  padding: 0 0 0 20px;
  border-left: 2px solid #ccc;
}

.help_you_decide_quiz_cover {
  background-repeat: no-repeat;
  background-size: 50% 110%;
  background-position: right;
  background-image: url("https://storage.googleapis.com/resagratia-webapp/misc/quiz_cover_desktop_view.png");
}

.quiz_background {
  background-repeat: no-repeat;
  background-size: 30% auto;
  background-position: right top;
  background-image: url("https://storage.googleapis.com/resagratia-webapp/misc/quiz_chapter_desktop_view.png");
}

.list_type {
  list-style-type: upper-alpha;
}

@keyframes popUp {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(var(--scale-factor));
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-popUp {
  animation: popUp 1s ease-out infinite;
}

.tooltip {
  display: none;
  position: absolute;
  top: 50%;
  left: -165px;
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  padding: 8px 18px;
  border-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  font-size: 12px;
  z-index: 100;
  height: 30px;
}

#whatsapp-chat-icon:hover .tooltip {
  display: block;
}

@keyframes spin {
  to {
    transform: rotateZ(360deg);
  }
}

.react__toast {
  font-size: 0.75rem;
  font-family: "ProductSans", sans-serif;
  /* width: fit-content !important; */
}

.react__toast__body {
  width: 100% !important;
}

@media (max-width: 1100px) {
  .heroTitle {
    font-size: 2.875rem !important /* 46px */;
    line-height: 3.3125rem !important /* 53px */;
  }
}

@media (max-width: 980px) {
  .heroTitle {
    font-size: 3.326625rem !important;
    line-height: 4.035196125rem !important;
  }

  .bootcampStructure {
    align-items: center;
  }

  .careerPathImage {
    min-width: 100%;
  }

  .help_you_decide_quiz_cover {
    background-repeat: no-repeat;
    background-size: 95% auto;
    background-position: right top;
    background-image: url("https://storage.googleapis.com/resagratia-webapp/misc/quiz_cover_mobile_view.png");
  }

  .quiz_background {
    background-repeat: no-repeat;
    background-size: 45% auto;
    background-position: right top;
    background-image: url("https://storage.googleapis.com/resagratia-webapp/misc/quiz_chapter_desktop_view.png");
  }
}

@media (max-width: 580px) {
  .heroTitle {
    font-size: 2rem !important;
    line-height: 2.42625rem !important;
  }

  .sectionTitle {
    font-size: 2rem !important;
    line-height: 2rem !important;
    margin-bottom: 2.5rem !important;
  }

  .circledImage {
    max-width: 9.375rem !important;
    max-height: 9.375rem !important;
  }
}

@media (max-width: 470px) {
  .circledImage {
    max-width: 6.25rem !important;
    max-height: 6.25rem !important;
    bottom: 2.625rem !important;
  }
}

@media (max-width: 420px) {
  .circledImage {
    bottom: 5rem !important;
  }
}

@media (max-width: 400px) {
  .enroll {
    display: none !important;
  }

  .enrollMobileView {
    display: block !important;
  }
}

@media (max-width: 692px) {
  .react__toast {
    width: 100% !important;
  }
}
