.authnavbar__link__dashboard {
  button {
    outline: none;
    padding: 8px;
    border: 1px solid #ff8c00;
    color: #ff8c00;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    &:hover {
      background: #0000000a;
      // border: 1px solid #ff8c00;
    }

    .dashboard__icon {
      font-size: 14px;
    }
  }
}
.authnavbar__auth__user {
  width: fit-content;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .authnavbar__auth__user__container {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .account__icon {
      color: #808080;
    }
  }

  .authnavbar__noauth__user__container {
    border: 1px solid #004085;
    border-radius: 4px;
    background: #cce5ff;
    padding: 8px 16px;

    p {
      color: #004085 !important;
    }
  }

  .authnavbar__auth__arrow__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .arrow__icon {
      color: #808080;
      cursor: pointer;
    }
  }

  p {
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: 700;
  }
}
