.authmobile__navbar {
  // height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  color: #111;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  z-index: 9999;
  position: fixed;
  // top: 0;

  .authmobile__navbar__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .authmobile__navbar__logo {
      width: 150px;
      height: 28px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .authmobile__navbar__icon {
      display: flex;
      align-items: center;

      .authmobile__menu__icon,
      .mobile__search__icon {
        color: #2b2c54;
        font-size: 32px;
      }
    }
  }

  .authmobile__navbar__elements {
    top: 0;
  }

  .authmobile__navbar__elements__banner {
    top: 50px;
  }

  .authmobile__navbar__elements,
  .authmobile__navbar__elements__banner {
    width: calc(100vw - 50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    z-index: 9999999;
    border-radius: 4px 0 0 4px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    overflow-y: auto !important;

    .authmobile__user__actions {
      justify-self: end;
    }

    .authmobile__link__dashboard {
      button {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        border: 1px solid #ff8c00;
        color: #ff8c00;
        padding: 12px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        .dashboard__icon {
          font-size: 16px;
        }
      }
    }

    .authmobile__logout__button {
      // margin: 1rem 0;

      button {
        width: 100%;
        outline: none;
        color: red;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .authmenu__element__container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      margin: 32px 0;
      padding: 16px 0;
      border: 1px solid red;

      .menu__element__item {
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba($color: #111, $alpha: 0.2);

        ul {
          list-style: none;

          li {
            cursor: pointer;
            color: #808080;
            padding: 8px 0;
            font-size: 13px;
          }
        }

        p {
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          color: #111;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .authmenu__elements {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;

    .authmenu__element__container {
      display: flex !important;
      flex-direction: row !important;
      width: inherit !important;
      flex-wrap: wrap !important;
      justify-content: space-between;

      .menu__element__item {
        border-bottom: none !important;
      }
    }
  }
  .mobile__link__dashboard,
  .mobile__logout__button {
    width: 300px !important;
    margin: 0 auto !important;
    padding: 20px 36px !important;
    position: absolute;
  }

  .mobile__link__dashboard {
    bottom: 15% !important;
    left: 8% !important;
  }

  .mobile__logout__button {
    bottom: 15% !important;
    right: 8% !important;
  }
}