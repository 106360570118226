.web-announcement {
  background-size: contain;
  background-image: url("https://storage.googleapis.com/resagratia-webapp/misc/banner_bg_stars.png");
}

.ten-percent-off {
  display: none;
}

@media (max-width: 980px) {
  .image-container {
    max-width: 250px;
  }
}

@media (max-width: 930px) {
  .image-container {
    max-width: 200px;
  }
}

@media (max-width: 830px) {
  .image-container {
    max-width: 150px;
  }
}

@media (max-width: 760px) {
  .image-container {
    display: none;
  }
}

@media (max-width: 760px) {
  .web-announcement {
    justify-content: center;
  }
}

@media (max-width: 760px) {
  .ten-percent-off {
    display: block;
  }
}

@media (max-width: 430px) {
  .ten-percent-off > img {
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media (max-width: 380px) {
  .ten-percent-off > img {
    width: 3rem;
    height: 3rem;
  }
  .ten-percent-off {
    left: 8px;
  }
}
