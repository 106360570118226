.toggle__switch {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 12px;
    font-weight: 600;
    color: rgba($color: #2b2c54, $alpha: 0.7);
    text-transform: uppercase;

    span {
      background: #2b3c54;
      color: #f4bd52;
      font-size: 12px;
      padding: .5rem;
      margin-left: .5rem;
      border-radius: 4px;
    }
  }
}

.toggle__switch__checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle__switch__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 35px;
  height: 25px;
  background: #2b2c54;
  border-radius: 150px;
  position: relative;
  transition: background-color 0.2s;
  cursor: pointer;

  .toggle__switch__button {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
}

.toggle__switch__checkbox:checked
  + .toggle__switch__label
  .toggle__switch__button {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
