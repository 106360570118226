.errorcomponent {
  .errorcomponent__container {
    max-width: 1100px;
    height: calc(100vh - 60px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .errorcomponent__content {
      display: flex;
      width: 40%;
      height: 250px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background: #2b2c54;
      border-radius: 30px;

      .errorcomponent__img {
        max-width: 350px;
        height: auto;
        position: absolute;
        top: -55%;
        z-index: 3;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .errorcomponent__heading {
        h3 {
          font-size: 14px;
          line-height: 1.7;
          color: #f4bd52;
          font-weight: 600;
        }
      }

      .errorcomponent__paragraph {
        padding: 8px 0;
        width: 70%;

        p {
          font-size: 12px;
          color: #fff;
          line-height: 1.8;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .errorcomponent {
    margin: 0 16px !important;

    .errorcomponent__content {
      width: 100% !important;
    }
  }
}

@media (min-width: 696px) and (max-width: 969px) {
  .errorcomponent {
    .errorcomponent__content {
      width: 50% !important;
    }
  }
}
